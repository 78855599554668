import React ,{ useState } from "react";
import styled from "styled-components";
import FooterComp from "../components/FooterComp";
import HeaderComp from "../components/HeaderComp";
import emailjs from "emailjs-com"

const FrontLayout = ({ children }) => {
   const [moblie, setMoblie] = useState(false);
   const [model , setModel] = useState(false)
   const [fName, setfName] = useState('');
   const [lName, setlName] = useState('');
   const [phone, setPhone] = useState('');
   const [email, setEmail] = useState('');
   const [service, setService] = useState('')
   const [adress , setAdress] = useState('')
   const [message, setMessage] = useState('')
   const [loading, setLoading]  = useState(false)
   const [status , setStatus]   =  useState(false)
 

  const sendEmail = () => {
  
    setLoading(true)

    const frmdetails = {
       Fullname : fName +" "+ lName,
       phone : phone,
       email : email,
       service:service,
       address:adress,
       message
    }

    console.log("send data true",templateParam)
     emailjs.send('service_12ejsen','template_51vfy6j', frmdetails, 'user_fhPYUZxS9hSN7MMg3ItUd')
      .then((response) => {
    
        console.log('SUCCESS!', response.status, response.text);
       if(response.status === 200){
        setStatus(true)
        setLoading(false)
       }
      }, (err) => {
        setLoading(false)
        console.log('FAILED...', err);
     });
    // console.log("new game ============------->",frmdetails);
}

   const getData = (data) =>{
     setMoblie(!moblie)
   }
    
   const templateParam = {
    name: 'James',
    notes: 'Check this out!',
    message:"hello my name is judge love",
    email:"whitetreefamily@gmail.com",
    from_name:"love judge",
    to_email :"texting texting",
    password:"chukwuka"
  }

  

   const modalShow = (data) =>{
      // console.log("data like modalShow ---------->",data)
      setModel(!data)
      // console.log("data like modalShow second  ---------->",model )
   }
   
  const closeModel = () =>{
     setModel(!model)
  }
  

  return (
    <Wrapper>
        <HeaderComp  checkMoblie={moblie} model={model} modalShow={modalShow} sendData={getData}/>
          <div className="main">{children}</div>
            {
                model ? 
                <div className="all-model">
            
                  <div className="profile-pre-loader"> 
                    {/* <p> */}
                      <a  href="#" onClick={closeModel}>
                        <img  src="https://res.cloudinary.com/codepally/image/upload/v1621205127/codepally%20website/Close_yv4r1f.svg" />
                      </a> 
                    {/* </p> */}
                    <h1>How do you want to build your product?</h1>
                    <form onSubmit={()=> sendEmail()}>
                    <div className="home-model-sub">
                      <div class="columns" >
                        <div class="column is-half">
                          <div class="field">
                            <label class="label">First Name</label>
                            <div class="control">
                              <input class="input is-small" type="text"   onChange={e => setfName(e.target.value)} placeholder=" First name" required/>
                            </div>
                          </div>
                        </div>
                        <div class="column">
                          <div class="field">
                            <label class="label">Last Name</label>
                            <div class="control">
                              <input class="input is-small" type="text" onChange={e=>setlName(e.target.value)} placeholder=" Last name" required/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns" >
                        <div class="column is-half">
                          <div class="field">
                            <label class="label">Email</label>
                            <div class="control">
                              <input class="input is-small" type="email" onChange={e=>setEmail(e.target.value)} placeholder=" Email" required/>
                            </div>
                          </div>
                        </div>
                        <div class="column">
                          <div class="field">
                            <label class="label">Phone number</label>
                            <div class="control">
                              <input class="input is-small" type="number" onChange={e=>setPhone(e.target.value)} placeholder="Phone"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns" >
                        <div class="column is-half">
                          <div class="field">
                            <label class="label">Service</label>
                            <div class="control">
                              <input class="input is-small" type="text" onChange={e=>setService(e.target.value)} placeholder="e.g. alexsmith@gmail.com"/>
                            </div>
                          </div>
                        </div>
                        <div class="column">
                          <div class="field">
                            <label class="label">Company adress (optional)</label>
                            <div class="control">
                              <input class="input is-small" type="text" onChange={e=>setAdress(e.target.value)} placeholder="e.g. alexsmith@gmail.com"/>
                            </div>
                          </div>
                         </div>
                       </div>
                        <div class="field">
                          <div class="control">
                            <textarea class="textarea " onChange={e=>setMessage(e.target.value)} placeholder="Message"></textarea>
                          </div>
                        </div>

                  
                      </div>
                        <div className="home-layout-buttom">
                          {
                            loading ?
                              <button class="button is-loading is-info is-rounded home-costom">Loading</button>
                            :
                            <div>
                              {
                                status ?
                                <>
                                  <p style={{textAlign:"center",color:"hsl(141, 53%, 53%)"}}>We send you a replay in the next 24 hours </p>
                                </>
                                :
                                <button class="button is-info is-rounded home-costom" >
                                  Submit
                                </button>
                              }

                            </div>
                          }

                        </div>


                      </form>


                    
                      <div>

                    </div>
                  </div>
                </div>
                :
                ""
            }

        <FooterComp />
    </Wrapper>
  );
};

export default FrontLayout;

const Wrapper = styled.div`
  /* background-color: red; */
  height: 100%;
  min-height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr auto;
`;
