import { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import FrontLayout from "./layouts/FrontLayout";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import  AboutPage from "./pages/aboutPage"
import ContectPage from "./pages/contectPage"
import DevelopementPage from "./pages/developmentPage"
import EngineeringPage from "./pages/engineeringPage"
import DedicatedPage from "./pages/dedicatedPage"
import Career from "./pages/career"
import  SolutionPage  from "./pages/solution"
import CasestudePage from "./pages/casestudies"
import FooterComp from "./components/FooterComp";
import HeaderComp from "./components/HeaderComp";

const ErrorLayout = ({ children }) => {


     
  return <Fragment> <HeaderComp ></HeaderComp>  {children} <FooterComp ></FooterComp></Fragment>;
};

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const Routes = () => {
  return (
    <Router>
      <Switch>
       <AppRoute exact path="/" component={HomePage} layout={FrontLayout} />
        <AppRoute exact path="/about-us" component={ AboutPage} layout={FrontLayout} />
        <AppRoute exact path="/development" component={DevelopementPage} layout={FrontLayout} />
        <AppRoute exact path="/contact-us" component={ContectPage} layout={FrontLayout} />
        <AppRoute exact path="/engineering" component={EngineeringPage} layout={FrontLayout} />
        <AppRoute exact path="/dedicated" component={DedicatedPage} layout={FrontLayout} />
        <AppRoute exact path="/career" component={Career} layout={FrontLayout} />
        <AppRoute exact path="/solution" component={SolutionPage} layout={FrontLayout} />
        <AppRoute exact path="/casestudies" component={CasestudePage} layout={FrontLayout } />
        <AppRoute component={ErrorPage} layout={ErrorLayout} />
      </Switch>
    </Router>
  );
};

export default Routes;
