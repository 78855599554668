import React, { useRef } from 'react';

import useSlider from '../../hooks/useSlider'

const Slider = ({images}) => {
    
    const slideImage = useRef(null)
    const slideText = useRef(null)
    const slideText1 = useRef(null)
    const slideText2 = useRef(null)
    const slideText3 = useRef(null)
    const { goToPreviousSlide, goToNextSlide } = useSlider(slideImage, slideText,
     slideText1,
     slideText2, 
     slideText3,
     images)

    return (

        <div className="home-slider-page">
                <div class="columns">
                    <div class="column is-half">
                       <div className="home-slider" ref={slideImage} >

                        </div>
                    </div>
                    <div class="column">
                        <br/>
                        <br/>
                          <div className="slider--feature">
                             <h1 ref={slideText} ></h1>
                             <h2 ref={slideText1}></h2>
                             <h3 ref={slideText2} ></h3>
                             <p ref={slideText3} ></p>
                         </div> 
                         <div className="home-slide-arrow">
                            <a onClick={goToPreviousSlide} >
                                <img src="https://res.cloudinary.com/codepally/image/upload/v1621205973/codepally%20website/homeright_vw2eio.svg" />
                            </a>
                            <a onClick={goToNextSlide} >
                              <img src="https://res.cloudinary.com/codepally/image/upload/v1621205969/codepally%20website/homeleft_xr6li9.svg" />
                            </a>
                        </div>     
                       
                    </div>
    
                 </div>
             </div>

          
    );
}

export default Slider;