

import react from "react"



const SolutionPage = () =>{

    return(
       <section >
           <div className="solution"> 
              <div className="solution-banner">
                <div class="columns">
                    <div class="column is-half">
                        <div className="solution-page">
                            <h1>
                            Yes, we can 
                            <br/>
                            build it
                            </h1>
                            <p>
                            We build strong relationships with our clients by doing three things: being accountable for our projects, making our processes transparent, and delivering excellent results.
                            </p>
                        </div>
                    </div>
                    <div class="column">
                       <div>
                        <img src="https://res.cloudinary.com/codepally/image/upload/v1622554971/codepally%20website/Group_1661_1_wievca.jpg" />
                        </div> 
                    </div>
                 </div>
               </div>
                <div className="solution-team">
                    <div class="columns">
                        <div class="column">
                          <a href="/dedicated">
                            <div className="solution-team-sub">
                              <img src="https://res.cloudinary.com/codepally/image/upload/v1621205242/codepally%20website/star_isbprb.svg"  />
                              <h1>
                              Dedicated Team
                              </h1>
                              <p>
                              The notion of a dedicated team comprises 
                                a customer and Codepally team,that acts as an intermediary to find personnel 
                                for the customer’s project.
                              </p>
                              <a>learn more</a>
                           </div>
                          </a>
                           
                        </div>
                        <div class="column">
                          <a href="/engineering">
                            <div className="solution-team-sub">
                                <img src="https://res.cloudinary.com/codepally/image/upload/v1621205266/codepally%20website/two_hevo5c.svg"  />
                                <h1>
                                Product Engineering
                                </h1>
                                <p>
                                To create a successful product it is necessary to combine profound technical experience and understanding of advanced business strategies
                                </p>
                                <a>learn more</a>
                             </div>
                           </a> 
                        </div>
                        <div class="column">
                          <a href="/development">
                            <div className="solution-team-sub">
                              <img src="https://res.cloudinary.com/codepally/image/upload/v1621204395/codepally%20website/Vector_hq7gd7.svg"  />
                              <h1>
                              Development
                              </h1>
                              <p>
                              With Codepalley team you can get full cycle services to build custom software for 
                             a broad range of verticals and functional areas.
                              </p>

                              <a>learn more</a>
                            </div>
                            </a>
                        </div>
                    </div>
                 </div>
                 <div className="solution-icon">
                   <h1>
                   We develop and
                    <br/>
                   deliver
                   </h1>
                   <div class="columns">
                      <div class="column">
                         <div className="solution-develop">
                           <img src="https://res.cloudinary.com/codepally/image/upload/v1621206124/codepally%20website/windows_yr3ud0.svg"  />
                                <h1>
                                Web Development
                                </h1>
                                <p>
                                With our website development, you do not have to adjust your speficications to
                                 the capabilities of a given platform or change your design visions to fit a template- we
                                  deliver the work precisely to your requirements project.
                                </p>
                         </div>
                      </div>
                      <div class="column">
                        <div className="solution-develop solution-color-1">
                           <img src="https://res.cloudinary.com/codepally/image/upload/v1621205917/codepally%20website/apple_va2khz.svg"  />
                                <h1>
                                IOS & Android Development
                                </h1>
                                <p>
                                Building a custom website does not neccessarily means reinventing the wheel. We make use
                                 of agile methodology in our project to fast track 
                                the development timeframe while maintaining the highest quality of code.
                                </p>
                          </div>
                      </div>
                      <div class="column">
                        <div className="solution-develop ">
                           <img src="https://res.cloudinary.com/codepally/image/upload/v1621205947/codepally%20website/connect_ehgvzh.svg"  />
                                <h1>
                                Digital Solution
                                </h1>
                                <p>
                                Whether you have a clear specification or just a vague idae 
                                of what the website should look like, we work with you to analyze 
                                and document the website functionality.
                                </p>
                         </div>
                      </div>
                      
                    </div>
                    <div class="columns">
                      <div class="column">
                         <div className="solution-develop solution-color-2">
                           <img src="https://res.cloudinary.com/codepally/image/upload/v1621205919/codepally%20website/buildeye_kb6jzx.svg"  />
                                <h1>
                                Business Branding
                                </h1>
                                <p>
                                We help create a branding strategy by mapping out a social media strategy that aligns 
                                with the Company's brand 
                                identity by writting other digital assets that accurately reflect the brand voice.
                                </p>
                                
                           
                         </div>
                      </div>
                      <div class="column">
                        <div className="solution-develop solution-color-3">
                           <img src="https://res.cloudinary.com/codepally/image/upload/v1621205218/codepally%20website/paint_yyqixv.svg"  />
                                <h1>
                                User Interface/ User Experience
                                </h1>
                                <p>
                                Once we understand your needs it is time to get to the drafting board. 
                                We will be sharing our sketches and asking for your feedback with review.
                                </p>
                            
                          </div>
                      </div>
                      <div class="column">
                        <div className="solution-develop solution-color-4">
                           <img src="https://res.cloudinary.com/codepally/image/upload/v1621205924/codepally%20website/catificat_p8tbh3.svg"  />
                                <h1>
                                IT Strategy
                                </h1>
                                <p>
                                All our websites are built to requirements and the expected user traffic is one of the most important ones.
                                 Your website will be able to stoically withstand the hordes of user and extend features.
                                </p>

                         </div>
                      </div>
                      
                    </div>
                 </div>
                 <div className="solution-icon">
                      <h1>
                        Cooperation Methods
                      </h1>
                      <div class="columns">
                          <div class="column">
                             <div className="solution-cooperation">
                             <img src="https://res.cloudinary.com/codepally/image/upload/v1621205143/codepally%20website/gless_pce6ie.svg"  />
                                <h1>
                                Expert Crew
                                </h1>
                                <p>
                                The number of experts for your project depends on the scope of work. Hence, we 
                                can provide you with one or several specialists, and we have full responsibility.
                                </p>
                             </div>
                          </div>
                          <div class="column">
                           <div className="solution-cooperation">
                             <img src="https://res.cloudinary.com/codepally/image/upload/v1621205136/codepally%20website/day_qwlujw.svg"  />
                                <h1>
                                Product Management
                                </h1>
                                <p>
                                We are responsible for a full development
                                 cycle that begins with planning and ends with implementation and subsequent support.
                                </p>
                              </div>  
                          </div>
                          <div class="column">
                            <div className="solution-cooperation">
                            <img src="https://res.cloudinary.com/codepally/image/upload/v1621204302/codepally%20website/message_lbv3im.svg"  />
                                <h1>
                                Service Management
                                </h1>
                                <p style={{marginBottom:"7%"}}>
                                If you need only one service like design or testing, 
                                we are ready to take care of it and meet your expectations.
                                </p>
                             </div>
                          </div>
                          
                        </div>

                  
                 </div>
                 <div className="solution-icon">
                      <h1>
                      Our Process
                      </h1>
                      <div class="columns">
                          <div class="column">
                             <div className="solution-crew">

                                <h1>
                                   <b>Expert Crew</b> 
                                </h1>
                                <p>
                                The number of experts for your project depends on the scope of work. Hence, we 
                                can provide you with one or several specialists, and we have full responsibility.
                                </p>
                             </div>
                          </div>
                          <div class="column">
                          <div className="solution-crew">
                           
                                <h1>
                                <b>Product Management</b>
                                </h1>
                                <p>
                                We are responsible for a full development
                                 cycle that begins with planning and ends with implementation and subsequent support.
                                </p>
                              </div>  
                          </div>
                          <div class="column">
                          <div className="solution-crew">
                      
                                <h1>
                                <b>Service Management</b> 
                                </h1>
                                <p>
                                If you need only one service like design or testing, 
                                we are ready to take care of it and meet your expectations.
                                </p>
                             </div>
                          </div>
                          
                        </div>
                 </div>
                 <div className="about-page-ready solution-footer">
                    <h1>
                          <b>
                            Ready to start your
                          <br/>
                            project with us?
                          </b> 
                    </h1>

                      <a className="home-ready-btc" href="/contact-us"> 
                        GET STARTED <img src="https://res.cloudinary.com/codepally/image/upload/v1621205101/codepally%20website/arrowline_i5ixsj.svg" />
                      </a>
                </div>
            </div>   
        </section>
    )
}



export default  SolutionPage ;