
import react from "react"




const CasestudePage  =( )=>{
    return (
        <div>
         <div className="casestudePage-body">
           
             <h1>
                 Our Casestudies
             </h1>
             <p>How codepalley power ambitious organisations. </p>

             <div className="casestudePage-sub">
             <div class="columns">
                <div class="column">
                  <div className="casestudePage-card">
                      <img  src="https://res.cloudinary.com/codepally/image/upload/v1621205948/codepally%20website/case1_u7i22v.svg" />
                       <h5>

                           Coming soon
                       </h5>
                      <h4>
                      <b> Ridora</b>
                     
                      </h4>
                  </div>
                </div>
                <div class="column">
                  <div className="casestudePage-card">
                       <img  src="https://res.cloudinary.com/codepally/image/upload/v1621205963/codepally%20website/case3_yztgbi.svg" />
                       <h5>
                           Coming soon
                       </h5>
                      <h4>
                      <b> Ridora</b>
                      </h4>
                  </div>
                </div>
                <div class="column">
                  <div className="casestudePage-card">
                      <img  src="https://res.cloudinary.com/codepally/image/upload/v1621205963/codepally%20website/case3_yztgbi.svg" />
                       <h5>
                       <b> Ridora</b>
                       </h5>
                      <h4>
                      Ridora
                      </h4>
                   </div>
                </div>
               
                </div>
             </div>
             <div className="casestudePage-sub">
             <div class="columns">
                <div class="column">
                  <div className="casestudePage-card">
                      <img  src="https://res.cloudinary.com/codepally/image/upload/v1621205950/codepally%20website/case2_tevpil.svg" />
                       <h5>
                           Coming soon
                       </h5>
                      <h4>
                      <b> Ridora</b>
                      </h4>
                  </div>
                </div>
                <div class="column">
                  <div className="casestudePage-card">
                       <img  src="https://res.cloudinary.com/codepally/image/upload/v1621205950/codepally%20website/case2_tevpil.svg" />
                       <h5>
                           Coming soon
                       </h5>
                      <h4>
                      <b> Ridora</b>
                      </h4>
                  </div>
                </div>
                <div class="column">
                  <div className="casestudePage-card">
                      <img  src="https://res.cloudinary.com/codepally/image/upload/v1621205950/codepally%20website/case2_tevpil.svg" />
                       <h5>
                           Coming soon
                       </h5>
                      <h4>
                      <b> Ridora</b>
                      </h4>
                   </div>
                </div>
               
                </div>
             </div>

         </div>
         <div className="home-page-ready casestude-footer">
           <h1>
               <b>
               Ready to start your
               <br/>
               project with us?
               </b> 
           </h1>

           <a className="home-ready-btc"  href="/contact-us">  
           GET STARTED <img src="https://res.cloudinary.com/codepally/image/upload/v1621205101/codepally%20website/arrowline_i5ixsj.svg" />
           </a>
       </div>
       </div>
    )
     
}


export default CasestudePage