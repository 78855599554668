import React from "react";

const FooterComp = () => {
  return (
    <footer className=" footer">
       <div className="footer-sub">
          <div class="columns">
            <div class="column is-one-third">
               <div className="footer-three">
                   <a href="/"><img src="https://res.cloudinary.com/codepally/image/upload/v1621206034/codepally%20website/newlogo_yv5qad.svg" width="60%" /></a>
                  <h3>
                     Your business partner solutions
                  </h3>
                  <h6>
                  You focus on your business growth while we <br/>
                  concentrate on the  technology to accelerate the growth
                  </h6>
                  <div className="footer-icon">
                     <img src="https://res.cloudinary.com/codepally/image/upload/v1621206044/codepally%20website/phonefooter_ccqpzn.svg" /> 
                       <p> 59C Old Aba Road, Rumuobiakani, Port Harcort, Rivers State Nigeria.</p>
                  </div>
                  <div className="footer-icon">
                     <img src="https://res.cloudinary.com/codepally/image/upload/v1621204230/codepally%20website/homefooter_z2jssw.svg" /> 
                       <p>  +234 704 722 2200 </p>
                  </div>
                </div>
            </div>
            <div class="column">
              <div className="footer-three1">
                  <h1>
                     <b>Quick Links</b>
                  </h1>
                  <ul>
                    <li><a href="/about-us" style={{ color:"#434343",textDecoration:"node !important"}}>Company</a></li>
                    <li><a href="/casestudies" style={{ color:"#434343",textDecoration:"node !important"}}>Results</a></li>
                    <li><a href="/solution" style={{ color:"#434343",textDecoration:"node !important"}}>Solution</a></li>
                    {/* <li>Blog</li> */}
                    {/* <li>Career</li> */}
                  </ul>
               </div>
            </div>
            <div class="column">
            <div className="footer-three1">
                  <h1>
                     <b>Products</b>
                  </h1>
                  <ul>
                    <li>LearnBeta</li>
                    <li>Ridora</li>
                   
                  </ul>
               </div>
            </div>
            <div class="column">
              <div className="footer-three1">
                  <h1>
                     <b>Partnerships</b>
                  </h1>
                  <ul>
                    <li>Gojeje</li>
                    <li>360Menu</li>
                    <li>HosLo</li>
                   
                  </ul>
               </div>
            </div>
            
          </div>
       </div>
    </footer>
  );
};

export default FooterComp;
