export default [
    {
        src: 'https://res.cloudinary.com/codepally/image/upload/v1625224611/Manjojo/Group_1696_fymspa.jpg',
        text: 'Manjojo Logitics  ',
        text1 : 'Codepally Technology services were execellent, resulting to a website with a very high reach in search engine results.',
        text2 : 'Joseph Ezenwa',
        text3 : 'CEO of Manjojo Logitics'
       },
    {
     src: 'https://res.cloudinary.com/codepally/image/upload/v1625239866/codepally%20website/unnamed_l1v2js.png',
     text: 'Lucky Joe Investments Limited',
     text1:  'Codepally Technology timely met all our expectations with top notch services, and quickly addressed customer concerns. ',
     text2:  'Nnaemeka',
     text3:  'CEO of Lucky Joe Investments Limited'
    },
    {
     src: 'https://res.cloudinary.com/codepally/image/upload/v1625233807/Manjojo/Group_1697_vumpzv.png',
     text: 'Rexhot',
     text1 : 'Codepally Technology team collaboratively managed our work, giving us a beautifully creative site, and they well communicated. ',
     text2 : 'George',
     text3 : 'CEO of Rexhot'
    }
]