import React from "react";
import { NavLink } from "react-router-dom";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import YouTube from "react-youtube";
import Slider from "../components/caro/Slide";
import Images from "../components/caro/image";

const HomePage = () => {
  const opts = {
    height: "490",
    width: "740",
  };

  const videoSrc = "https://www.youtube.com/embed/";
  return (
    <section>
      <div className="home-page">
        {/*first Guy here  */}
        <div  className="home-miss-banner">
            <div class="columns">
              <div class="column is-two-fifths">
                <div className="banner-left">
                  <div className="banner-left-line"></div>
                  <h6> Your business solutions partner</h6>
                  <div className="home-banner-we">
                    <h1>
                      We are ready
                      <br />
                      to help your business
                      <br />
                      grow
                    </h1>
                  </div>

                  <p>
                    You focus on your business growth while we concentrate on the
                    technology to
                    <br />
                    accelerate the growth
                  </p>
                  <div className="banner-left-play">
                    <a href="/contact-us" class="button is-medium">GET STARTED</a>{" "}
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621205154/codepally%20website/iconPlayvideo_t7mpbv.svg" alt="" />
                  </div>
                </div>
              </div>

              <div className="banner-right">
                <div className="banner-right-sub">
                  <img src="https://res.cloudinary.com/codepally/image/upload/v1625134340/codepally%20website/Group_1020_lh5ejv.jpg" alt="" />
                </div>
              </div>
            </div>
            <div style={{width:"100%"}}>
           <div class="columns">
              <div class="column is-half">
              <div className="banner-left-sub">
                <div className="banner-left-client">
                <div className="banner-left-line-client"></div> 
                <h6> Our Clients </h6>
                  <h2>Leading digital transformation for all industry.</h2>

                  <h3>
                    Haven succesfully created and launched simple and 
                    complex apps that truly delighted our clients, we
                     created a healthy working relationship with them.
                  </h3>
                </div>
                 </div>
              </div>
             
                
              <div class="column">
                <div class="company-holder">
                    <div className="company-btn">    
                        <img src="https://res.cloudinary.com/codepally/image/upload/v1621205103/codepally%20website/Bitmap_wgrpt5.svg" alt="" />
                    </div>
           
                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621204359/codepally%20website/Ringo_kwpobq.svg" alt="" />
                    </div>

    

                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621204269/codepally%20website/mapan_y2jh8q.svg" alt="" />
                    </div>
                  </div>
                  <div class="company-holder">
                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621206021/codepally%20website/monnfy_uqg8ai.svg" alt="" />
                    </div>

           
                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621205987/codepally%20website/investa_z6wjtt.svg" alt="" />
                    </div>

                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621205220/codepally%20website/paystack_kf7o24.svg" alt="" />
                    </div>

                 


                </div>
                <div class="company-holder">
                    
                   <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621204227/codepally%20website/flutterwave_bc7zgo.svg" alt="" />
                    </div>
                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621204169/codepally%20website/120_kqkc1a.svg" alt="" />
                    </div>

                </div>
                     
                 
            
                  
             </div>
          
           </div>
        </div>
        </div>
       

        {/* LEading DIgital transformation here */}
       

        {/* <div class="columns">
          <div className="spiral-left">
          <img src="https://res.cloudinary.com/codepally/image/upload/v1618339938/left_ghgbyg.svg" alt="" />
          </div>
      
          <div class="column is-half">
            <div className="banner-left-sub">
              <div className="banner-left-client">
                <div className="banner-left-line-client"></div>
                <h6> Our Clients </h6>

                <h2>Leading digital transformation for all industry.</h2>

                <h3>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </h3>
              </div>
            </div>
          </div>

          <div className="company-holder">
            <div class="columns is-multiline">
            <div className="company-btn">    
                        <img src="https://res.cloudinary.com/codepally/image/upload/v1621205103/codepally%20website/Bitmap_wgrpt5.svg" alt="" />
                    </div>
           
                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621204359/codepally%20website/Ringo_kwpobq.svg" alt="" />
                    </div>

    

                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621204269/codepally%20website/mapan_y2jh8q.svg" alt="" />
                    </div>

                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621206021/codepally%20website/monnfy_uqg8ai.svg" alt="" />
                    </div>

           
                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621205987/codepally%20website/investa_z6wjtt.svg" alt="" />
                    </div>

                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621205220/codepally%20website/paystack_kf7o24.svg" alt="" />
                    </div>

                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621204227/codepally%20website/flutterwave_bc7zgo.svg" alt="" />
                    </div>

                    <div className="company-btn">    
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1621204169/codepally%20website/120_kqkc1a.svg" alt="" />
                    </div>

                    
            </div>
          </div>
        </div> */}

       

        <div class="home-why" >
          <div class="columns">
            <div class="column is-two-fifths">
              <div className="home-why-left">
                <img src="https://res.cloudinary.com/codepally/image/upload/v1622560033/codepally%20website/Group_1647_1_sower5.png" alt="" width="100%" />
              </div>
            </div>
            <div class="column">
              <div className="home-why-right">
                <div className="banner-left-line-why"></div>
                <h6> Why Choose Us </h6>
                <h1>
                  Here is some reason why <br />
                  your company should choose us
                </h1>

                <div className="home-card-body">
                  <div class="columns">
                    <div class="column is-half">
                      <div className="home-card-first">
                        <div className="home-card-first-m">
                          <img src="https://res.cloudinary.com/codepally/image/upload/v1621205191/codepally%20website/mark_uml0ft.svg" alt="" />
                          <h2>Do more, Spend Less</h2>
                          <p>
                            At codepalley, we help you reduce the cost of runing
                            your business using exclusive technology and digital
                            tools.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div className="home-card-second">
                        <div className="home-card-first-m">
                          <img src="https://res.cloudinary.com/codepally/image/upload/v1621204374/codepally%20website/start_r6uard.svg" alt="" width="70" />
                          <h2>Collaborate, Do More</h2>
                          <p>
                            As against individualism, we collaboratively work towards achieving the best at 
                            every single job we do.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="/images/testcard.svg" alt=""  width="556"/> */}
              </div>
            </div>
          </div>
        </div>
        <div class="home-Services">

          <div class="columns">
            <div class="column is-two-fifths">
                <div class="home-Services-left">
                <div className="home-Services-left-line"></div>
                <h6>Our Services </h6>
                <h1>
                Leading digital transformation for all industry.
                </h1>
                <p>We redefine software development and delivery by focusing on 
                  a data-oriented engineering framework that unearths value for
                   the enterprise and de-risks the engagement process.
                </p>
                </div>
            </div>
            <div class="column">
               <div  class="home-Services-right">
                    <div class="home-Services-right-sub">
                      <img src="https://res.cloudinary.com/codepally/image/upload/v1622287114/ridora%20website/e_lyekww.svg"/>
                      <h1>Engineering Team Augmentation</h1>
                      <p>We build a team of top software engineers from Africa and Europe in days not months.</p>
                    </div>
                    <div class="home-Services-right-sub">
                      <img src="https://res.cloudinary.com/codepally/image/upload/v1622287114/ridora%20website/a_miyg1y.svg"/>
                      <h1>Application Development</h1>
                      <p> We develope enterprise-grade performance applications built for web and mobile use.</p>
                    </div>
               </div>
               <div  class="home-Services-right">
                    <div class="home-Services-right-sub">
                      <img src="https://res.cloudinary.com/codepally/image/upload/v1622287114/ridora%20website/m_udprsw.svg"/>
                      <h1>Artificial Intelligence and Machine Learning</h1>
                      <p>In Codepally, we birth products built to extract maximum value, driven by machine power and unmatched intelligence.</p>
                    </div>
                    <div class="home-Services-right-sub">
                      <img src="https://res.cloudinary.com/codepally/image/upload/v1622287114/ridora%20website/en1_abnr5j.svg"/>
                      <h1>Analytics and Big Data</h1>
                      <p>We also deal on next generation products driven by intelligent insights and more.</p>
                    </div>
               </div>
            </div>
        
          </div>

        </div>
        <div className="home-location">
          <h1>Watch how we work</h1>
          <div className="home-location-sub">
            <img src="https://res.cloudinary.com/codepally/image/upload/v1621204256/codepally%20website/location_wypyzh.svg" alt="" width="70" />
          </div>
          <div className="home-location-video">
           
            <video
              controls
              autoPlay
              src="https://res.cloudinary.com/codepally/video/upload/v1625236703/codepally%20website/Codepally_web_video_t4gdun.mp4"
            />
          </div>
        </div>
        <div className="home-Community">

               <h1>Our Community</h1>
               <p>We have the skilled and experienced.</p>
                <div class="columns">
                  <div class="column is-three-fifths">
                      <div className="home-Community-icon"></div>
                      <div className="home-Community-sub">
                        <div className="home-Community-sub-list">

                        </div>
                        <div className="home-Community-sub-list2">

                        </div>
                      </div>
                      <div className="home-Community-africa">
                         <div className="home-Community-africa-sub"></div>
                         <div className="home-Community-africa-sub1"></div>
                      </div>
                  </div>
                  <div class="column">
                      <div className="home-Community-info">
                          <h1>100+ software Engineers</h1>
                          <p>Our engineers are solution oriented, logical and focused. Our team comprise of back-end engineers, front-end engineers, mobile app developers, customer experience designers and more.</p>
                          <h1>100+  Technical Skill</h1>
                          <p> Skill include Ruby , Python,  GO , Java , Java Script ,React-Native Postgres , MongoDB , and many more  .</p>
                          <h1>2+ Countries</h1>
                          <p> With a geographic footprint that covers Africa America Europe and more.</p>
                      </div>
                  </div>
                </div>
        </div>
        <div className="home-Focus">
               <h1>Our Focus</h1>
               <div className="home-Focus-sub">
                 <div className="home-Focus-card">
                   <p><img src="https://res.cloudinary.com/codepally/image/upload/v1622288507/codepally%20website/dollar_lzfrrm.svg" /></p>
                   <h1>Payment</h1>
                   <h4>A bank on every Phone</h4>
                   <p>Innovation to drive inclusion by bringing a bank to every phone,  enabling people and businesses to receive, spend, save, borrow, and manage money.</p>
                 </div>
                 <div className="home-Focus-card">
                 <p><img src="https://res.cloudinary.com/codepally/image/upload/v1622288507/codepally%20website/consumer_yin03x.svg" /></p>
                   <h1>Data insight</h1>
                   <h4>decision making</h4>
                   <p>Innovation to enable people and businesses to collect accurate data online and offline across industry and customer segments for smarter decisions.</p>
                 </div>
                 <div className="home-Focus-card">
                 <p><img src="https://res.cloudinary.com/codepally/image/upload/v1622288506/codepally%20website/brein_zkfh1t.svg" /></p>
                   <h1>Quality</h1>
                   <h4>Learning</h4>
                   <p>Innovation to aid learning distribution, mobility, and management to improve learning experience and outcomes.</p>
                 </div>
                 <div className="home-Focus-card">
                 <p><img src="https://res.cloudinary.com/codepally/image/upload/v1622288506/codepally%20website/finger_wtzst1.svg" /></p>
                   <h1>Identity enroll</h1>
                   <h4>verify anyone</h4>
                   <p>Innovation and incentives to enable people and businesses to enroll, keep, use, and verify any identity.</p>
                 </div>
                 
               </div>
               <div class="home-Branding">
                <div class="columns">
                  <div class="column is-half">
                  <div class="home-Branding-card">
                     <h1>
                      We work with 
                      Startups
                     </h1>
                     <p>We accomodate both international 
                       and local startups by providing services and products that well 
                       soothe their existence. We would work with you and ensure we give 
                       your business the right boost needed to meet your target customers.</p>
                 </div>
                  </div>
                  <div class="column">
                      <div class="home-Branding-card1">
                        <h1>
                        Branding and Design
                        </h1>
                        <p>At Codepally, we place premium on branding and design more seriously. To us, it is not
                          just about color and symbols, but we build your brand to tell
                           your story in the most explicit manner because we see your 
                           branding and design as everything.</p>
                    </div>
                  </div>
                
                </div>

               
                
              </div>
        </div>
       
        <div className="home-page-what">
          <h1>What they say</h1>
          <h3>
            We are impassioned about the satisfaction of our clients, giving them 
            unforgettable brand experience. From their reviews, our clients attest
             to our ability to deliver seamlessly and excellently.
              Their reviews...
          </h3>
        </div>
        <Slider images={Images} />
     

        <div className="home-page-ready">
          <h1>
            <b>
              Ready to start your
              <br />
              project with us?
            </b>
          </h1>

          <a className="home-ready-btc" href="/contact-us">
            GET STARTED <img src="https://res.cloudinary.com/codepally/image/upload/v1621205101/codepally%20website/arrowline_i5ixsj.svg" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default HomePage;
