import React, {useState}from "react";
import { NavLink } from "react-router-dom";
import { DefaultPlayer as Video } from 'react-html5video';
import emailjs from "emailjs-com"
const ContectPage = () => {

  
   const [fullName, setFullname] = useState('');
   const [phone, setPhone] = useState('');
   const [email, setEmail] = useState('');
   const [service, setService] = useState('')
   const [adress , setAdress] = useState('')
   const [message, setMessage] = useState('')
   const [loading, setLoading]  = useState(false)
   const [status , setStatus]   =  useState(false)
 

  const sendEmail = () => {
  
    setLoading(true)

    const frmdetails = {
       Fullname : fullName,
       phone : phone,
       email : email,
       service:service,
       address:adress,
       message
    }

    console.log("send data true", frmdetails)
     emailjs.send('service_12ejsen','template_51vfy6j', frmdetails, 'user_fhPYUZxS9hSN7MMg3ItUd')
      .then((response) => {
    
        console.log('SUCCESS!', response.status, response.text);
       if(response.status === 200){
        setStatus(true)
        setLoading(false)
       }
      }, (err) => {
        setLoading(false)
        console.log('FAILED...', err);
     });
    // console.log("new game ============------->",frmdetails);
   }

  return (
    <section >
         <div className="contact-page">
             <div className="contact-page-sub">
                <div class="columns">
                        <div class="column is-half">
                            <div className="contect-p">
                                <h1>
                                <b>
                                Let’s Talk About <br/>
                                Everything</b>  
                                </h1>
                                <p>
                                You need a team that listens; one that meticulously travels deep <br/>
                                into your mind to understand your needs and craft solutions <br/>
                                that make you stand out in your industry.
                                <br/>
                                <br/>
                                Don’t stop at being exceptional, do it like Codepalley.
                                </p>

                                <img src="https://res.cloudinary.com/codepally/image/upload/v1621205139/codepally%20website/elementsNetwork_xhdxoy.svg" />
                            </div>
                        </div>
                      
                          <div class="column">
                          <form onSubmit={()=>sendEmail()}>
                              <div className="input-form">
                                <div class="field">
                                  <label class="label about-costom-laber">Full Name</label>
                                  <div class="control">
                                    <input class="input " type="text" onChange={e=>setFullname(e.target.value)} placeholder="Full Name" style={{borderRadius:"6px"}} required/>
                                  </div>
                                </div>
                                <div class="field">
                                  <label class="label about-costom-laber" >Phone Number</label>
                                  <div class="control">
                                    <input class="input" type="number" onChange={e=>setPhone(e.target.value)} placeholder="Phone Number"  style={{borderRadius:"6px"}}  required/>
                                  </div>
                                </div>
                                <div class="field">
                                  <label class="label about-costom-laber">Email</label>
                                  <div class="control">
                                    <input class="input " type="text" onChange={e=>setEmail(e.target.value)} placeholder="Email" style={{borderRadius:"6px"}}  required/>
                                  </div>
                              </div>
                                <div class="field">
                                    <label class="label about-costom-laber">Address</label>
                                    <div class="control">
                                      <input class="input " type="text" onChange={ e=>setAdress(e.target.value)} placeholder="Address" style={{borderRadius:"6px"}}  required/>
                                    </div>
                                </div>
                              </div>
                              <div class="field new-input">
                                  <label class="label about-costom-laber">Message</label>
                                  <div class="control">
                                  <textarea class="textarea" onChange={e=>setMessage(e.target.value)} placeholder="Message"  required></textarea>
                                  </div>
                                </div>
                                {
                                    loading ?
                                      <button class="button is-loading is-info is-rounded ">Loading</button>
                                    :
                                    <div>
                                      {
                                        status ?
                                        <>
                                          <p style={{textAlign:"center",color:"hsl(141, 53%, 53%)"}}>We send you a replay in the next 24 hours </p>
                                        </>
                                        :
                                        <div class="contect-btc" >
                                           <button >
                                              Submit
                                            </button>

                                        </div>
                                       
                                      }

                                    </div>
                                  }
                                 </form>
                          </div>


                        
                </div>

             </div>
             <div>
                    <div className="about-page-ready">
                    <h1>
                            <b>
                            Ready to start your
                            <br/>
                            project with us?
                            </b> 
                    </h1>

                        <a className="home-ready-btc"  href="/contact-us"> 
                        GET STARTED <img src="https://res.cloudinary.com/codepally/image/upload/v1621205101/codepally%20website/arrowline_i5ixsj.svg" />
                        </a>
                    </div>
             </div>
          
        </div>
    </section >
  );
};

export default ContectPage;
