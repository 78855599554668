import React from "react";
import { NavLink } from "react-router-dom";
import { DefaultPlayer as Video } from 'react-html5video';
// import 'react-html5video/dist/styles.css';
import YouTube from "react-youtube"
// import "./"


const AboutPage = () => {


  const opts = {
    height: '490',
      width: '740',
   
  };
  return (
    <section >
      <div className="about-page">
          <div className="about-blog">
            <div className="about-blog-sub">
                <div class="columns">
                    <div class="column is-half">
                      <div className="about-infor">
                        
                          <h1><b>Teamwork, Solid, <br/>Great Performance</b></h1>
                      </div>
                    </div>
                    <div class="column">
                     <div className="about-infor">
                       <p>
                       Codepalley's  ongoing success is fundamentally driven by the tremendous  quality of our team. We are trustworthy, committed, and talented. We<br/>
                      daily harness the collective expertise of our full team to provide our 
                      clients with fast, focused solutions.
                       </p>
  
                      </div>
                    </div>
                   
                  </div>
                  <div class="columns">
                    <div class="column is-three-fifths">
                       <div className="about-banner">
                          <img src="https://res.cloudinary.com/codepally/image/upload/v1625152929/codepally%20website/Group_1653_pkmpgn.jpg" />
                       </div>
                    </div>
                    
                    <div class="column">
                      <div class="about-two-image">
                          <div className="about-img1">
                            <img src="https://res.cloudinary.com/codepally/image/upload/v1622554971/codepally%20website/Group_1654_kn3bzp.jpg"  />
                        </div>
                        <div className="about-img2">
                            <img src="https://res.cloudinary.com/codepally/image/upload/v1622557676/codepally%20website/Group_926_1_nwvarq.jpg" style={{borderRadius:"10px"}} />
                        </div>
                      </div>
                    </div>
                  </div>
             </div>
          </div>
          <div >
            <div className="about-mission">
              <div class="columns">
                <div class="column is-half">
                    <div className="about-m-img">
                      <img src="https://res.cloudinary.com/codepally/image/upload/v1622554971/codepally%20website/Group_1656_oun5ls.jpg"/>
                  </div>
                </div>
                <div class="column">
                  <div className="about-m-p">
                    <h1>Our Mission</h1>
                    <ul>
                      <li>
                      <div className="footer-icon">
                          <img src="https://res.cloudinary.com/codepally/image/upload/v1621206014/codepally%20website/mark_dn1mep.svg" /> 
                        
                            <p> 
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br/>
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            </p>
                      
                        </div>
                      </li>
                      <li>
                      <div className="footer-icon">
                      <img src="https://res.cloudinary.com/codepally/image/upload/v1621206014/codepally%20website/mark_dn1mep.svg" /> 
                        
                            <p> 
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br/>
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            </p>
                      
                        </div>
                      </li>
                      <li>
                      <div className="footer-icon">
                          <img src="https://res.cloudinary.com/codepally/image/upload/v1621206014/codepally%20website/mark_dn1mep.svg" /> 
                        
                            <p> 
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br/>
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            </p>
                      
                        </div>
                      </li>
                      
                      
                    </ul>
                  </div>
                </div>
                
              </div>
            </div>


            
          </div>

          <div className="about-vision">
            <div class="columns">
              <div class="column is-half">
               <div className="about-vision-p">
                   <h1>Our Vision</h1>
                   <ul>
                     <li>
                     <div className="footer-icon">
                     <img src="https://res.cloudinary.com/codepally/image/upload/v1621206014/codepally%20website/mark_dn1mep.svg" /> 
                      
                          <p> 
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br/>
                          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                          </p>
                    
                      </div>
                     </li>
                     <li>
                     <div className="footer-icon">
                     <img src="https://res.cloudinary.com/codepally/image/upload/v1621206014/codepally%20website/mark_dn1mep.svg" /> 
                      
                          <p> 
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br/>
                          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                          </p>
                    
                      </div>
                     </li>
                     <li>
                     <div className="footer-icon">
                        <img src="https://res.cloudinary.com/codepally/image/upload/v1621206014/codepally%20website/mark_dn1mep.svg" /> 
                      
                          <p> 
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br/>
                          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                          </p>
                    
                      </div>
                     </li>
                     
                    
                   </ul>
                </div>
              </div>
              <div class="column">
                <div>
                   <img src="https://res.cloudinary.com/codepally/image/upload/v1622554971/codepally%20website/Group_921_gqftlm.jpg" />
                </div>
                
              </div>
              
            </div>
          </div>
    
          <div className="about-page-ready">
              <h1>
                    <b>
                      Ready to start your
                    <br/>
                      project with us?
                    </b> 
              </h1>

                <a className="home-ready-btc"  href="/contact-us"> 
                  GET STARTED <img src="https://res.cloudinary.com/codepally/image/upload/v1621205101/codepally%20website/arrowline_i5ixsj.svg" />
                </a>
              </div>
          </div>
    </section >
  );
};

export default AboutPage;
