import React from "react";
import { NavLink } from "react-router-dom";
import { DefaultPlayer as Video } from 'react-html5video';
// import 'react-html5video/dist/styles.css';
import YouTube from "react-youtube"
// import "./"


const EngineeringPage = () => {

  return (
    <section >
       <div className="developement-blog">
            <div className="developement-banner"> 
                <div class="columns">
                    <div class="column is-two-fifths">
                        <div className="developement-benner-p">
                        <h1>
                        Product engineering
                        </h1>
                            <p>
                            With Improved IT you can get full cycle services to build custom software for a broad range of vertical and functional areas.
                            </p>
                        </div>
                    </div>
                    <div class="column">
                        <div className="developement-benner">
                           <img src="https://res.cloudinary.com/codepally/image/upload/v1621205989/codepally%20website/Ill_el203z.svg" />
                        </div>
                   
                    </div>
              </div>
            </div>
            </div>
            <div>
            <div className="developement-solution">
                <div class="columns">
                    <div class="column is-half">
                       <div className="developement-p">
                       <h1> Custom web 
                      
                           applications</h1> 
                       </div>
                    </div>
                    <div class="column">
                        <div className="developement-p">
                          <p>
                          If you want to shift your commercial activity to a new advanced level, 
                           a custom web app is a proper way to discover new horizons. If you need 
                           to build a new solution or improve the functions of the existing one, our 
                           team is ready to accomplish the task in a quick and cost-efficient way.
                          </p> 
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="developement-solution">
                <div class="columns">
                    <div class="column is-half">
                       <div className="developement-p">
                       <h1> Mobile-first 

                        
                           websites</h1> 
                       </div>
                    </div>
                    <div class="column">
                        <div className="developement-p">
                          <p>
                          Today people say that if your site is not mobile-friendly,
                           it’s not on the internet. This fact caused the necessity to 
                           adapt web products to advanced criteria such as dimension or 
                           resolution of the screen. It’s necessary to adjust your website 
                           and make it look fascinating and work smoothly on each type of device 
                           to keep and increase the number of your audience.
                          </p> 
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="developement-solution">
                <div class="columns">
                    <div class="column is-half">
                       <div className="developement-p">
                       <h1> Startup software

                           <br/>
                           products</h1> 
                       </div>
                    </div>
                    <div class="column">
                        <div className="developement-p">
                          <p>
                          Today people say that if your site is not mobile-friendly, it’s not
                           on the internet. This fact caused the necessity to adapt web products
                            to advanced criteria such as dimension or resolution of the screen. 
                            It’s necessary to adjust your website and make it look fascinating 
                            and work smoothly on each type of device to keep and increase the number
                             of your audience.
                          </p> 
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div className="developement-solution">
                <div class="columns">
                    <div class="column is-half">
                       <div className="developement-p">
                       <h1> Cloud development

                           <br/>
                           development</h1> 
                       </div>
                    </div>
                    <div class="column">
                        <div className="developement-p">
                          <p>
                          You have already chalked out a niche and an idea, but have doubts in the 
                          following steps? The best way is to rely on our team that has rich experience 
                          in start-up MVPs and products creation. We will not only help you reduce 
                          time-to-market, but will also save your budget and protect the code.
                          </p> 
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="developement-top">

            </div>
            
          <div className="about-page-ready">
              <h1>
                    <b>
                      Ready to start your
                    <br/>
                      project with us?
                    </b> 
              </h1>

                <a className="home-ready-btc"  href="/contact-us"> 
                  GET STARTED <img src="https://res.cloudinary.com/codepally/image/upload/v1621205101/codepally%20website/arrowline_i5ixsj.svg" />
                </a>
         </div>
       </div>
    </section >
  );
};

export default EngineeringPage;
