import "@fortawesome/fontawesome-free/css/all.css";
import React , { useState, useEffect } from "react";
import FontFaceObserver from 'fontfaceobserver'
import { Provider } from "react-redux";

import Routes from "./routes";
import store from "./redux/store";
import "./scss/index.scss";




const App = () => {
  
useEffect(() => {
   Fonts();
});

const Fonts = () => {
  const link = document.createElement('link')
  link.href = 'https://fonts.googleapis.com/css?family=Exo Aclonica Open Sans italic Sofia:300,400,500,700,900'
  link.rel = 'stylesheet'

  document.head.appendChild(link)

  const roboto = new FontFaceObserver('Exo Aclonica Open Sans italic Sofia')

  roboto.load().then(() => {
    document.documentElement.classList.add('Exo Aclonica  Open Sans italic Sofia ')
  })
}
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default App;
