import React from "react";
import { NavLink } from "react-router-dom";
import { DefaultPlayer as Video } from 'react-html5video';
// import 'react-html5video/dist/styles.css';
import YouTube from "react-youtube"
// import "./"


const DedicatedPage = () => {

  return (
    <section >
       <div className="developement-blog">
            <div className="developement-banner"> 
                <div class="columns">
                    <div class="column is-half">
                        <div className="dedicated-benner-p">
                            <h1>
                            Dedicated  <br/><span> team </span> 
                
                            </h1>
                        </div>
                    </div>
                    <div class="column">
                        <div className="dedicated-benner">
                            <div className="dedicated-sub">
                            The notion of a dedicated team comprises a customer and ImproveIT
                         company, that acts as an intermediary to find personnel for the customer’s
                          project. As a result, we’ve got a comprehensive recruiting model.
                          </div>

                           {/* <img src="/images/startD.svg" /> */}
                        </div>
                     </div>
                   </div>
                </div>
             </div>
             <div className="dedicated-ben">
                 <h2>
                  Benefits of the dedicated team model
                 </h2>
                <div className="dedicated-ben-icon">
                    <div class="columns">
                        <div class="column">
                            <div className="dedicated-ben-icon-sub">
                                 <img src="https://res.cloudinary.com/codepally/image/upload/v1621204368/codepally%20website/search_e2ab3n.svg" />
                                 <p>
                                 Flexibility and scalability
                                 </p>
                            </div>
                        </div>
                        <div class="column">
                           <div className="dedicated-ben-icon-sub">
                                 <img src="https://res.cloudinary.com/codepally/image/upload/v1621204223/codepally%20website/eye_ggzzbt.svg" style={{marginBottom:"14%"}}/>
                                 <p>
                                 Visual communication
                                 </p>
                            </div>
                        </div>
                        <div class="column">
                          <div className="dedicated-ben-icon-sub">
                                 <img src="https://res.cloudinary.com/codepally/image/upload/v1621205100/codepally%20website/arowupdown_uqjvng.svg" />
                                 <p>
                                 Efficiency
                                 </p>
                            </div>
                        </div>
                        <div class="column">
                          <div className="dedicated-ben-icon-sub">
                                 <img src="https://res.cloudinary.com/codepally/image/upload/v1621204458/codepally%20website/worring_crl3eq.svg" />
                                 <p>
                                 Team intergration
                                 </p>
                            </div>
                        </div>
                        <div class="column">
                          <div className="dedicated-ben-icon-sub">
                                 <img src="https://res.cloudinary.com/codepally/image/upload/v1621204182/codepally%20website/clock_cc3tja.svg" />
                                 <p>
                                 Time management
                                 </p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            <div className="dedicated-model">
                <h1>
                How does the recruiter model work?
                </h1>
                <div className="dedicated-model-sub">

                    <div class="columns">
                        <div class="column is-half">
                            <div className="dedicated-model-sub1">
                                <h3> <span>First</span> step</h3>
                                <p>
                                First, the customer’s demands as to the quantity and qualifications of personnel for the project should be outlined.
                                </p>
                            </div>
                        </div>
                        <div class="column">
                           <div className="dedicated-model-sub1 dedicated-1">
                                <h3> <span>First</span> step</h3>
                                <p>
                                First, the customer’s demands as to the quantity and qualifications of personnel for the project should be outlined.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="columns">
                        <div class="column is-half">
                            <div className="dedicated-model-sub1 dedicated-2">
                                <h3> <span>First</span> step</h3>
                                <p>
                                First, the customer’s demands as to the quantity and qualifications of personnel for the project should be outlined.
                                </p>
                            </div>
                        </div>
                        <div class="column">
                           <div className="dedicated-model-sub1 dedicated-3">
                                <h3> <span>First</span> step</h3>
                                <p>
                                First, the customer’s demands as to the quantity and qualifications of personnel for the project should be outlined.
                                </p>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>

            <div className="about-page-ready">
              <h1>
                    <b>
                      Ready to start your
                    <br/>
                      project with us?
                    </b> 
              </h1>

                <a className="home-ready-btc"  href="/contact-us"> 
                  GET STARTED <img src="https://res.cloudinary.com/codepally/image/upload/v1621205101/codepally%20website/arrowline_i5ixsj.svg" />
                </a>
          </div>

    </section >
  );
};

export default DedicatedPage;
