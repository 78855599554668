import React from "react";


const ErrorPage = () => {
  return (
    
      <div className="error-page">
        <h3>Something went wrong</h3>
        <h1><b>Page Not Found</b></h1>
          <a className="error-btn" href="/">
              GET STARTED <img src="https://res.cloudinary.com/codepally/image/upload/v1621204359/codepally%20website/rightArrow_qklzh6.svg"/>
          </a>
      </div>
  );
};

export default ErrorPage;
