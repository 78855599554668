import React  ,{ useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const HeaderComp = ( props) => {

  const {checkMoblie,  sendData , modalShow , model} = props
  



  const showMoblie = (data)=>{

    sendData(data)
  }


  const clickModel = (data) =>{
    modalShow (data)
  }


  return (
          <div className="nav-cos">

             <nav class="navbar " role="navigation" aria-label="main navigation">
         
            <div class="navbar-brand">
              <a class="navbar-item" href="/">
                <img src="https://res.cloudinary.com/codepally/image/upload/v1621206034/codepally%20website/newlogo_yv5qad.svg" alt=""  />
              </a>
            </div>
            
            <div id="navbarBasicExample" class="navbar-menu">
            
              <div class="navbar-start">
                
                </div>
                    {/* <ul className="nav-left">
                      <li >
                          <a href="/about-us" >
                          <small className="nav-font">Company</small>
                          </a>{" "}
                        </li>
                        <li >
                        <a href="/casestudies" >
                          <small className="nav-font">Casestudies</small>
                          </a>{" "}
                        </li>
                        <li >
                          <a href="/solution" >
                            <small className="nav-font">Solution</small>
                          </a>{" "}
                        </li>
                          <li >
                          <a href="/contect-us" >
                        <small className="nav-font">Contect</small>
                          </a>{" "}
                        </li>
                        <li >
                        <a href="#" onClick={()=>clickModel(model)} className="btn btn-primary">
                          MESSAGE US
                          </a>{" "}
                        </li>

                      </ul> */}


                  </div>
                  <div class="navbar-end" >
                    <div class="navbar-item" style={{width:"120%"}}>
                            <ul className="nav-left">
                      <li >
                          <a href="/about-us" >
                          <small className="nav-font">Company</small>
                          </a>{" "}
                        </li>
                        <li >
                        <a href="/casestudies" >
                          <small className="nav-font">Casestudies</small>
                          </a>{" "}
                        </li>
                        <li >
                          <a href="/solution" >
                            <small className="nav-font">Solution</small>
                          </a>{" "}
                        </li>
                          <li >
                          <a href="/contact-us" >
                        <small className="nav-font">Contact</small>
                          </a>{" "}
                        </li>
                        <li >
                        <a href="#" onClick={()=>clickModel(model)} className="btn btn-primary">
                          MESSAGE US
                          </a>{" "}
                        </li>

                      </ul>
                    </div>
                  </div>
                  <ul className="nav-left1" >
                        <a  href="#" onClick={()=>showMoblie(checkMoblie)}>
                          {
                            checkMoblie ?
                              <i class="fas fa-times" style={{color:"#000",fontSize:"140%"}}></i>
                            
                            :
                            <img src="https://res.cloudinary.com/codepally/image/upload/v1621205195/codepally%20website/menu_t3w7kz.svg" alt=""  />
                          }
                        
                        </a>
                      
                  </ul>
              </nav>
          {
            checkMoblie?
              <div className="sidebar">
                 <a href="/about-us" >
                 <div className="header-list">
                   
                     <p> <b> <small >Company</small></b></p> 
                 </div>
                 </a>
                 <a href="/solution" >
                 <div className="header-list">
                   
                     <p> <b> <small >Solution</small></b></p> 
                 </div>
                 </a>
                 <a href="/development" >
                 <div className="header-list">
                   
                     <p> <b> <small >Development</small></b></p> 
                 </div>
                 </a>
                 <a href="/dedicated" >
                 <div className="header-list">
                   
                     <p> <b> <small >Engineering</small></b></p> 
                 </div>
                 </a>
                 <a href="/contact-us" >
                 <div className="header-list">
                   
                     <p> <b> <small >Contact</small></b></p> 
                 </div>
                 </a>
              </div>
            :
               <></>

          }
         
            
        </div>

        
  );
};

export default HeaderComp;

// const Header = styled.header`
//    .nav-font{
//     font-family: Open Sans  !important;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 16px;
//     line-height: 22px;
//     text-decoration: none;
//     color: #575757;
//     margin-top:15%;
//     // position:relative;
//     // top:5px
//    }
//    .nav-font{
//     img {
//       width: 20%;
   
//     }
//     @media screen and (max-width: 768px) {
//       margin-left: 1rem;
//     }
//    }
//   .nav-brand {
//     img {
//       width: 100%;
//       max-width: 10rem;
//     }
//     @media screen and (max-width: 768px) {
//       margin-left: 1rem;
//     }
//   }
  
//   @media screen and (max-width: 768px) {
//     .btn {
//       padding: 0;
//       cursor: pointer;
//       &-primary {
//         color: #FF6443;
//         background-color: white;
//       }
//       &-outline-primary {
//         border: 0;
//       }
//     }
//   }
// `;
