import react from "react"


const CareerPage =()=>{

    return (
        <section >
               <div  className="home-page is-flex-fullhd"> 
                 <div className="home-full-banner">
                    <div class="columns">
                        <div class="column is-three-fifths">
                        <div className="banner-left">
                        
                            <div className="banner-left-line">
                            </div>
                            <h6>  Your business solutions partner</h6>
                                <div className="home-banner-we" >
                                    <h1>
                                    <b>
                                        We are ready
                                        <br/>
                                        to help your business 
                                        <br/>
                                        grow up
                                    </b>
                                    
                                    </h1>
                               </div>
                                <p>
                                    You focus on your business growth while we concentrate on the  technology to 
                                    <br/>
                                    accelerate the growth
                                </p>
                                <div className="banner-left-play">
                                        <a class="button is-medium">GET STARTED</a> <img src="/images/iconPlayvideo.svg" alt=""  />
                                </div>
                                <div className="banner-left-client">
                                    <div className="banner-left-line-client">
                                    </div> 
                                    <h6>   Our Clients </h6>

                                    <h2>
                                        Leading digital transformation for
                                        
                                        all industry.
                                    </h2>

                                    <h3>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                    </h3> 
                                 </div>
                               </div>
                            </div>
                            <div class="column">
                                <div className="banner-right">
                                    <div className="banner-right-sub">
                                    <img src="/images/home.svg" alt="" />
                                    <div className="banner-left-mobile">
                                        <div className="banner-left-line-client">
                                        </div> 
                                        <h6>   Our Clients </h6>

                                        <h2>
                                        Leading digital transformation for
                                            <br/>
                                        all industry.
                                        </h2>

                                        <h3>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                        </h3>
                                        
                                    </div>
                                    </div>
                                <div className="home-company">
                                    <ul >
                                    <li>
                                        <div className="company-btn" >
                                                <img src="/images/Bitmap.svg" alt="" />
                                        </div>
                                    </li>
                                        <li>
                                        <div className="company-btn" >
                                                <img src="/images/Ringo.svg" alt=""  />
                                        </div>
                                    </li>
                                        <li>
                                        <div className="company-btn" >
                                                <img src="/images/mapan.svg" alt=""  />
                                        </div>
                                    </li>
                                        <li>
                                        <div className="company-btn" >
                                                <img src="/images/monnfy.svg" alt=""  />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="company-btn" >
                                                <img src="/images/investa.svg" alt="" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="company-btn" >
                                                <img src="/images/paystack.svg" alt=""  />
                                            </div>
                                    </li>
                                    <li>
                                        <div className="company-btn" >
                                                <img src="/images/flutterwave.svg" alt=""  />
                                            </div>
                                        </li>
                                        <li>
                                        <div className="company-btn" >
                                                <img src="/images/120.svg" alt=""  />
                                            </div>
                                        </li>
                                        </ul>
                                </div>
                                </div>
                            </div>
                    
                    </div>
                </div>
      
               </div>

               <div className="example">
cfyjdrtyh
               </div>


















































































            {/* <div  className="career-page">
              <div className="career-banner">
                 <div class="columns">
                    <div class="column is-half">
                        <div className="career-banner-p">
                        <h1>
                        Join our team
                        </h1>
                        <p>We are always excited about meeting new people and growing our team, so feel free to get in touch if you are a skilled or promising team player.  </p>
                        <a>JOIN OUR TEAM</a>
                        </div>
                    </div>
                     <div class="column">
                        <div className="career-banner-img">
                            <img src="/images/name.svg" />
                        </div>    
                    </div>
                 </div>
              </div>  

             <div className="career-vacancies"> 
             <h1>Vacancies</h1>
                <div className="career-vacancies-sub">
                  
                  <div class="columns">
                    <div class="column is-half">
                        <div className="career-vacancies-p career-costom">
                            <h1>Business Analyst</h1>
                            <p>Port Harcourt, Rivers</p>
                        </div>
                    </div>
                    <div class="column">
                        <div className="career-vacancies-p career-color1">
                            <h1>.NET Senior Software</h1>
                            <p>Port Harcourt, Rivers</p>
                        </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                        <div className="career-vacancies-p career-color1">
                            <h1>Middle .Net developer</h1>
                            <p>Port Harcourt, Rivers</p>
                        </div>
                    </div>
                    <div class="column">
                        <div className="career-vacancies-p career-color1">
                            <h1>.NET Tech Lead</h1>
                            <p>Port Harcourt, Rivers</p>
                        </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                        <div className="career-vacancies-p career-color1">
                            <h1>Big Data Engineer</h1>
                            <p>Port Harcourt, Rivers</p>
                        </div>
                    </div>
                    <div class="column">
                        <div className="career-vacancies-p career-color1">
                            <h1>Middle .Net developer</h1>
                            <p>Port Harcourt, Rivers</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </section>  

    )

}


export default CareerPage;